<template>
  <div>
    <div class="flex space-x-2" v-if="detail == false">
      <vs-button
        v-if="
          (status == 0 || status == 1) &&
          $store.getters['user/hasPermissions']('create')
        "
        class="mt-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
      <vs-button
        v-if="
          (status == 0 || status == 1) &&
          $store.getters['user/hasPermissions']('create')
        "
        class="mt-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleImport()"
        >Import</vs-button
      >
    </div>
    <div class="vx-col mb-6 mt-3" v-if="detail == false">
      <div class="vx-row w-1/2">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="territory.selected"
            :options="territory.options"
            :allow-empty="true"
            placeholder="Type to search"
            track-by="id"
            label="name"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} - {{ props.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} - {{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row w-1/2 mt-2">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer Group 1</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="customer_group_1.selected"
            :options="customer_group_1.options"
            :allow-empty="true"
            placeholder="Type to search"
            track-by="ID"
            label="code"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }}-{{ props.option.name }}
                </span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }}-{{ props.option.name }}
                </span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
    </div>
    <div class="vx-col w-full mb-6" v-if="detail == false">
      <div class="vx-row ml-4">
        <vs-button
          class="m-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-search"
          @click="onClickSearch"
        >
          Search
        </vs-button>
        <vs-button
          class="m-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-trash-2"
          @click="handleClear()"
          >Clear</vs-button
        >
      </div>
    </div>
    <div class="vx-row mb-12">
      <div v-if="detail == false" class="w-full">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th>Action</vs-th>
            <vs-th sort-key="code">CL Request ID</vs-th>
            <vs-th>Territory</vs-th>
            <vs-th>Customer Group 1</vs-th>
            <vs-th>Number of Customer</vs-th>
            <vs-th sort-key="updated_at">Evaluation Period</vs-th>
            <vs-th sort-key="valid_from">Valid From</vs-th>
            <vs-th v-if="isLog">Status</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <div class="flex space-x-1">
                  <vx-tooltip
                    text="Edit"
                    v-if="
                      data[indextr].is_generate == false &&
                      data[indextr].status == 0 &&
                      isLog == false &&
                      $store.getters['user/hasPermissions']('edit')
                    "
                  >
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickEditDraft(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip
                    text="Edit Data"
                    v-if="
                      data[indextr].is_generate == false &&
                      data[indextr].status == 1 &&
                      isLog == false &&
                      $store.getters['user/hasPermissions']('edit')
                    "
                  >
                    <feather-icon
                      icon="EyeIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickEdit(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip
                    text="Show Log"
                    v-if="
                      data[indextr].status == 1 &&
                      isLog == true &&
                      $store.getters['user/hasPermissions']('view')
                    "
                  >
                    <feather-icon
                      icon="EyeIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickShowLog(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip
                    text="Detail"
                    v-if="
                      data[indextr].status != 1 &&
                      data[indextr].status != 0 &&
                      isLog == false &&
                      $store.getters['user/hasPermissions']('view')
                    "
                  >
                    <feather-icon
                      icon="EyeIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickDetail(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip
                    text="Cancel"
                    v-if="
                      (data[indextr].status == 1 ||
                        data[indextr].status == 0) &&
                      data[indextr].is_generate == false &&
                      isLog == false &&
                      $store.getters['user/hasPermissions']('edit')
                    "
                  >
                    <feather-icon
                      icon="XIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickCancel(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip
                    text="Approve"
                    v-if="
                      data[indextr].is_generate == false &&
                      data[indextr].status == 1 &&
                      isLog == false &&
                      $store.getters['user/hasPermissions']('edit')
                    "
                  >
                    <feather-icon
                      icon="CheckIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickAccept(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip
                    text="generate"
                    v-if="
                      data[indextr].is_generate == false &&
                      data[indextr].status == 0 &&
                      isLog == false &&
                      $store.getters['user/hasPermissions']('edit')
                    "
                  >
                    <feather-icon
                      icon="CheckIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickGenerate(tr)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
              <vs-td :data="data[indextr].code">{{ data[indextr].code }}</vs-td>

              <vs-td :data="data[indextr].territory_name">{{
                data[indextr].territory_name
              }}</vs-td>
              <vs-td>
                {{
                  data[indextr].customer_group_name
                    ? data[indextr].customer_group_name
                    : "All"
                }}
              </vs-td>
              <vs-td>
                {{
                  data[indextr].count_customer
                    ? data[indextr].count_customer
                    : "-"
                }}
              </vs-td>
              <vs-td :data="data[indextr].evaluation_period">{{
                data[indextr].evaluation_period
              }}</vs-td>
              <vs-td :data="data[indextr].valid_from">{{
                data[indextr].valid_from
              }}</vs-td>
              <vs-td v-if="isLog">
                <ul>
                  <li>
                    Success :
                    {{ data[indextr].count_success }}
                  </li>
                  <li>
                    Failed :
                    {{ data[indextr].count_failed }}
                  </li>
                </ul>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div v-if="detail" class="w-full">
          <vs-row>
            <!-- form component -->
            <form-component
              v-if="
                isEdit == false && isEditDraft == false && isLogDraft == false
              "
              @closeDetail="handleClose"
              :id="id"
            ></form-component>
            <edit
              v-if="
                isEdit == true && isEditDraft == false && isLogDraft == false
              "
              @closeDetail="handleClose"
              :id="id"
              :show="isShow"
              :done="done"
            ></edit>
            <EditDraft
              v-if="
                isEditDraft == true && isEdit == false && isLogDraft == false
              "
              @closeDetail="handleClose"
              :id="id"
              :show="isShow"
              :done="done"
            ></EditDraft>
            <LogDetail
              v-if="
                isLog == true &&
                isEdit == false &&
                isEditDraft == false &&
                isLogDraft == true
              "
              @closeDetail="handleClose"
              :id="id"
              :data="dataSelected"
              :show="isShow"
              :done="done"
            ></LogDetail>
          </vs-row>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import FormComponent from "../form/form.vue";
import Edit from "../form/formEdit.vue";
import EditDraft from "../form/formEditDraft.vue";
import LogDetail from "../form/formEditDraftLog.vue";
export default {
  props: {
    selected: Object,
    status: Number,
    done: Boolean,
    generated: Number,
    isLog: Boolean,
  },
  components: {
    FormComponent,
    Edit,
    EditDraft,
    LogDetail,
  },

  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      isShow: false,
      deleteId: null,
      dataSelected: null,
      id: null,
      isEdit: false,
      isEditDraft: false,
      isLogDraft: false,
      table: this.tableDefaultState(),
      territory: {
        selected: 0,
        options: [],
      },
      customer_group_1: {
        selected: 0,
        options: [],
      },
      processing: 0,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "updated_at",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        status: null,
        end: 0,
        generateData: "",
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    handleCreate() {
      this.id = null;
      this.detail = true;
      this.isEdit = false;
      this.isEditDraft = false;
    },

    getData() {
      this.$vs.loading();
      if (this.status) {
        this.table.status = this.status;
      }
      console.log(">>>>>LLL", this.customer_group_1.selected);
      this.$http
        .get("/api/v1/master/credit-limit-regulers", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            status: this.table.status,
            generate: this.table.generateData,
            territory_id: this.territory.selected
              ? this.territory.selected.id
              : null,
            customer_group_id_1: this.customer_group_1.selected
              ? this.customer_group_1.selected.ID
              : null,
          },
        })
        .then((resp) => {
          this.table.data = resp.data.records;
          this.table.total = resp.data.total_record;
          this.table.totalPage = resp.data.total_page;
          this.table.totalSearch = resp.data.total_record_search;
          this.table.length = resp.data.total_record_per_page;
          if (this.table.data != null) {
            if (this.table.data.code == "Processing") {
              this.processing = 1;
            } else {
              this.processing = 0;
            }
            console.log(">>>>>LLL", this.table.data);
            this.table.data.forEach((item) => {
              if (item.customer_group != null) {
                let cgGroup = item.customer_group.some(
                  (group) => group.id === 0
                )
                  ? "All"
                  : item.customer_group
                      .filter((group) => group.id !== 0)
                      .map((group) => group.code)
                      .join(", ");

                item.customer_group_name = cgGroup;
              }
            });
          }

          this.setStartEnd();
          this.$vs.loading.close();
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch != 0) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    clickEdit(item) {
      this.id = item.id;
      this.detail = true;
      this.isEdit = true;
      this.isEditDraft = false;
    },
    clickDetail(item) {
      this.id = item.id;
      this.detail = true;
      this.isEdit = true;
      this.isShow = true;
    },
    clickShowLog(item) {
      this.id = item.id;
      this.detail = true;
      this.isEdit = false;
      this.dataSelected = item;
      this.isLogDraft = true;
    },
    clickEditDraft(item) {
      this.id = item.id;
      this.isEdit = false;
      this.isEditDraft = true;
      this.detail = true;
    },

    getAllTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/multiple-territory", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    getAllCustomerGroup1() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-group", {
          params: {
            order: "name",
            sort: "asc",
            level: 1,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customer_group_1.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    clickCancel(item) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: `Are you sure want to cancel this data?`,
        accept: (e) => {
          this.$vs.loading();
          this.$http
            .post("/api/v1/master/credit-limit-regulers-status", {
              id: item.id,
              status: 3,
            })
            .then((resp) => {
              if (resp.status == "success") {
                this.$vs.notify({
                  title: "Success",
                  text: "Cancel Success",
                  color: "success",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-check",
                });
                this.$vs.loading.close();
                this.getData();
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error",
                  text: resp.message,
                  color: "danger",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                });
              }
            });
        },
      });
    },
    handleClear() {
      this.territory.selected = null;
      this.customer_group_1.selected = null;
      this.getData();
    },
    onClickSearch() {
      this.getData();
    },
    clickAccept(item) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: `Are you sure want to process this data?`,
        accept: (e) => {
          this.$vs.loading();
          this.$http
            .post("/api/v1/master/credit-limit-regulers-status", {
              id: item.id,
              status: 2,
            })
            .then((resp) => {
              if (resp.status == "success") {
                console.log("LLLLLLLL");
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Success",
                  text: "Generate Success",
                  color: "success",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-check",
                });

                this.getData();
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error",
                  text: resp.message,
                  color: "danger",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-alert-circle",
                });
              }
            });
        },
      });
    },
    clickGenerate(item) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: `Are you sure want to process this data?`,
        accept: (e) => {
          this.$vs.loading();
          this.$http
            .post("/api/v1/master/credit-limit-regulers-v2", {
              id: item.id,
            })
            .then((resp) => {
              if (resp.status == "success") {
                this.$vs.loading.close();

                this.$vs.notify({
                  title: "Success",
                  text: "Approve Success",
                  color: "success",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-check",
                });
                this.getData();
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error",
                  text: resp.message,
                  color: "danger",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-alert-circle",
                });
              }
            });
        },
      });
    },
    handleImport(id) {
      this.$router.push({
        name: "credit-limit-reguler-import",

        query: { id: id, action: "Import", generated: this.generated },
      });
      this.id = id;
      this.detail = true;
    },
  },
  watch: {},
  mounted() {
    this.getAllTerritory();
    this.getAllCustomerGroup1();
    this.getData();
    this.$store.dispatch("user/getPermissions", "cl-reguler");
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
